import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './screens/home/HomePage';
import { ClientLayout } from './components/layouts/Layout';
import ContactPage from './screens/contact/ContactPage';
import ServicesPage from './screens/services/ServicesPage';
import PortfolioPage from './screens/portfolio/PortfolioPage';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<ClientLayout/>}>
            <Route path='/' element={<HomePage/>}/>
            <Route path='/contact' element={<ContactPage/>}/>
            <Route path='/service' element={<ServicesPage/>}/>
            <Route path='/portfolio' element={<PortfolioPage/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
