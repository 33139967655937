import { useEffect, useState } from "react";
import { CarouselBanner } from "../../components/banners/Banners";
import { ChallengesSection } from "../../components/challenges/Challenges";
import { FeatureSection } from "../../components/feature/Feature";
import { Preloader } from "../../components/preloader/Preloader";
import { ServicesSection } from "../../components/services/Services";
import { PricingSection } from "../../components/pricing/Pricing";
import ProjectsSection from "../../components/projects/Projects";

export default function HomePage() {

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(true);
        }, 3000);
    }, []);

    return (
        isLoading === false ?
            (
                <Preloader />
            ) :
            (
                <div className="HomePage">
                    <CarouselBanner />
                    <FeatureSection />
                    <ChallengesSection />
                    <ServicesSection />
                    <ProjectsSection />
                    <PricingSection />
                </div>
            )
    )
}