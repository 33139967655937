import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import {
    batteryXchange,
    codeMode,
    rentSrus,
    go_home_gps,
} from "../../assets/img/projects";

const projectsData = [
    {
        img: rentSrus,
        title: "RentsRUs",
        description:
            "The service aims to provide ease, security, and hassle-free transportation for various types of cargo, ensuring that shipments reach their destination reliably and on time.",
        stacks: [
            { name: "Laravel", color: "yellow" },
            { name: "Angular", color: "pink" },
            { name: "Mysql", color: "lightblue" },
            { name: "Google map", color: "lightgreen" },
            { name: "GCP", color: "blue" },
            { name: "linux", color: "white" },
        ],
        url: "https://rentsrus.com.mx/",
    },
    {
        img: codeMode,
        title: "CodeMode",
        description:
            "It offers a comprehensive learning experience where students receive a full hour of cumulative knowledge and dedicated guidance during each class session. Additionally, the service includes extra sessions whenever necessary to ensure the success of the students.",
        stacks: [
            { name: "HTML", color: "white" },
            { name: "JavaScript", color: "lightgreen" },
            { name: "PHP", color: "yellow" },
            { name: "wordpress", color: "pink" },
            { name: "Mysql", color: "lightblue" },
        ],
        url: "https://codemode.us/",
    },
    {
        img: batteryXchange,
        title: "BX",
        description:
            "This service focuses on enhancing user convenience and ensuring that mobile device users can stay connected and productive regardless of their location or battery life limitations.",
        stacks: [
            { name: "PHP", color: "white" },
            { name: "Wordpress", color: "blue" },
            { name: "Socket.io", color: "pink" },
            { name: "Firebase", color: "yellow" },
            { name: "AWS", color: "lightblue" },
            { name: "Mysql", color: "lightgreen" },
        ],
        url: "https://www.batteryxchange.co/",
    },
    {
        img: go_home_gps,
        title: "Go Home GPS",
        description:
            " GoHomeGPS provides real-time vehicle tracking capabilities, allowing customers to monitor the live geofence position of their vehicles on a map interface. Geofences can be set by customers to define specific areas or boundaries, and the platform alerts users when vehicles enter or exit these pblueefined zones.",
        stacks: [
            { name: "WordPress", color: "white" },
            { name: "BootStrap", color: "blue" },
            { name: "PHP", color: "pink" },
            { name: "Google Hosted Libraries", color: "yellow" },
            { name: "JQuery", color: "lightblue" },
            { name: "Mysql", color: "lightgreen" },
        ],
        url: "https://www.gohomegps.com/",
    },
];


function ProjectsSection() {
    return (
        <section className="projects-section">
            <div className="projects-area  mb-5 py-5">
                <Container>
                    <div className="section-title">
                        <h2 className="text-white">Case Studies</h2>
                    </div>
                    <Row>
                        {projectsData.map((item, index) => (
                            <Col xs={12} sm={6} className="mb-3 slide-left">
                                <div className="single-project overly-one">
                                    <div className="overly-two">
                                        <div className="project-img">
                                            <Image src={item.img} alt="Image" style={{ height: 500, width: "100%", objectFit: 'cover', objectPosition: 'top' }} />
                                        </div>
                                    </div>

                                    <a href={item.url} target="_blank" rel="noreferrer">
                                        <div className="project-content">
                                            <h2 className="title text-white mt-5">{item.title}</h2>
                                            <p className="project-description text-white">
                                                {item.description}
                                            </p>
                                            <hr className="text-white" />
                                            <p>
                                                {item.stacks.map((stack, index1) => (
                                                    <span
                                                        key={index1}
                                                        style={{
                                                            fontSize: "20px",
                                                            marginRight: "20px",
                                                            color: `${stack.color}`,
                                                        }}
                                                    >
                                                        {stack.name}
                                                    </span>
                                                ))}
                                            </p>
                                        </div>
                                    </a>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </section>
    );
}

export default ProjectsSection;
